import { Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import EarnedPoints from "../component/Point-Details/EarnedPoints";
import RedeemPoint from "../component/Point-Details/RedeemPoint";
import { useTranslation } from "react-i18next";
import NonRedeemPoint from "../component/Point-Details/NonRedeemPoint";
import CloseModalTab from "../component/Nav/CloseModalTab";
import CurrentYearPoints from "../component/Point-Details/CurrentYearPoints";
import { asyncWrap } from "../utils/utils";
import axios from "axios";
import { webAppConfigIsVisible } from "../utils/isVisible";
import { WebAppConfig } from "../context/WebAppConfig";

const PointsInformation = () => {
  // eslint-disable-next-line
  const [show, setShow] = useState(true);
  const webAppConfigCtx = useContext(WebAppConfig);
  const [totalPoint, setTotalPoint] = useState();
  const [active, setActive] = useState(2);
  // const history = useHistory();
  const { t } = useTranslation();

  const [currentYearPoints, setCurrentYearPoints] = useState(0);
  const [lastYearPoint, setLastYearPoints] = useState(0);
  const [previousdataPoints, setPreviousDataPoints] = useState(0);

  const GetPointInfo = async () => {
    const [error, result] = await asyncWrap(axios.get("/earnedPoints"));
    if (!result) {
      console.log(error);
      return;
    }
    setCurrentYearPoints(result.data.data?.currentYearPoints);
    setLastYearPoints(result.data.data?.lastYearPoints);
    setPreviousDataPoints(result.data.data?.previousDataPoints);
    setTotalPoint(result.data.data.totalPoints[1].point);
  };

  useEffect(() => {
    GetPointInfo();
  }, []);

  const optfilter = [
    // { title: t("ledger"), key: 1, webconfig: false },
    { title: t("redeem_points"), key: 2, webconfig: false },
    { title: t("gift_point"), key: 3, webconfig: false },
    {
      title: t("current_year"),
      key: 4,
      webconfig: true,
      webconfig_name: "Current Year",
    },
    {
      title: t("last_year"),
      key: 5,
      webconfig: true,
      webconfig_name: "Last Year",
    },
    {
      title: t("previous_data"),
      key: 6,
      webconfig: true,
      webconfig_name: "Previous data",
    },
  ];

  return (
    <Modal fullscreen={true} show={show}>
      {/* <Modal.Header> */}
      {/* <SecoundaryHeader
          text={t("points_information")}
          sideHide="false"
          onBack={() => {
            history.push("/home");
            setShow(false);
          }}
        /> */}
      {/* </Modal.Header> */}
      <Modal.Body style={{ marginTop: "30px" }}>
        <CloseModalTab />
        <div>
          <div
            style={{
              fontSize: "20px",
              color: "#7B7B7B",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Current Points
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center mt-1">
            <img
              style={{ marginRight: "10px", width: "25px", height: "25px" }}
              src="/assets/images/Redeem/trophy.png"
              alt="trophyimage"
            />
            <div
              style={{ color: "#3A69DD", fontSize: "25px" }}
              className="fw-bold"
            >
              {totalPoint}
            </div>
            <img
              style={{ marginLeft: "10px", width: "25px", height: "25px" }}
              src="/assets/images/Redeem/trophy.png"
              alt="tropyimage"
            />
          </div>
        </div>
        <div className="horizontal-scroll mt-2 d-flex flex-row gap-3">
          {optfilter.map((item, index) =>
            item.webconfig ? (
              webAppConfigIsVisible(item.webconfig_name, webAppConfigCtx) && (
                <div
                  key={index}
                  onClick={() => setActive(item.key)}
                  className={
                    item.key === active ? "pointInfo-active" : "pointInfo"
                  }
                >
                  {item.title}
                </div>
              )
            ) : (
              <div
                key={index}
                onClick={() => setActive(item.key)}
                className={
                  item.key === active ? "pointInfo-active" : "pointInfo"
                }
              >
                {item.title}
              </div>
            )
          )}
        </div>

        {active === 1 && <EarnedPoints />}

        {active === 2 && <RedeemPoint />}

        {active === 3 && <NonRedeemPoint />}

        {active === 4 && (
          <CurrentYearPoints
            title={t("current_year_points")}
            point={currentYearPoints && currentYearPoints[0]?.EarnPoints}
          />
        )}

        {active === 5 && (
          <CurrentYearPoints
            title={t("last_year_points")}
            point={lastYearPoint && lastYearPoint[0]?.EarnPoints}
          />
        )}

        {active === 6 && (
          <CurrentYearPoints
            title={t("previous_data_points")}
            point={previousdataPoints && previousdataPoints[0]?.EarnPoints}
          />
        )}
        {/* <Tabs defaultActiveKey="earn" id="uncontrolled-tab" className="mb-3">
          <Tab eventKey="earn" title={t("earned_points")}>
            <EarnedPoints />
          </Tab>
          <Tab eventKey="redeem" title={t("redeem_points")}>
            <RedeemPoint />
          </Tab>
          <Tab eventKey="nonredeem" title={t("non_redeem_points")}>
            <NonRedeemPoint />
          </Tab>
        </Tabs> */}
      </Modal.Body>
    </Modal>
  );
};

export default PointsInformation;
